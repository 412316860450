<template>
  <div class="white-email-container">
    <el-row class="header" type="flex">
      <el-col :span="8" class="search-option">
        <el-input
          v-model="searchKey"
          class="search-input"
          placeholder="请输入邮箱后缀"
          clearable
        ></el-input>
        <el-button style="margin: 0 8px;" type="primary" @click="handleSearch">
          搜索
        </el-button>
      </el-col>
      <el-col :span="6" :offset="4" class="create-option">
        <el-button type="primary" @click="showCreateDialog = true">
          添加邮箱
        </el-button>
      </el-col>
    </el-row>

    <el-table :data="emailList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="emailList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      :title="'添加邮箱'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="onClose"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="邮箱后缀">
          <el-input v-model="postForm.email_suffix"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchWhiteListEmail,
  postWhiteListEmail,
  deleteWhiteListEmail,
} from "@/api/active";

export default {
  data() {
    return {
      columns: [{ id: "email_suffix", label: "邮箱后缀" }],
      postForm: {
        email_suffix: "",
      },
      searchKey: "",
      showCreateDialog: false,
      emailList: {
        items: [],
        total: 0,
      },
      currentPage: 1,
      pageSize: 30,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let query = {};
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      query.email_suffix = this.searchKey;
      fetchWhiteListEmail(query).then((data) => {
        let json = data.data.data;
        // console.log(data.data.data, json);
        this.emailList.items = json.list.map((item, index) => {
          return { index: index, email_suffix: item };
        });
        this.emailList.total = json.total;
      });
    },
    handleSearch() {
      this.fetchData();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteWhiteListEmail({ email_suffix: row.email_suffix }).then(() => {
          this.$message({
            message: "删除成功",
            duration: 3000,
            type: "success",
          });
          this.fetchData();
        });
      });
    },

    onClose() {
      this.postForm = {
        status: -1,
        title: "",
        detail: "",
        subject: "",
        language: "",
      };
      this.showCreateDialog = false;
      this.isEdit = false;
    },
    onSubmit() {
      postWhiteListEmail(this.postForm).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.handleSuccess();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        }
      });
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.white-email-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;
    .search-input {
      width: 300px;
    }

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
